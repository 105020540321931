import { render, staticRenderFns } from "./CompanyCardDescription.vue?vue&type=template&id=7010d30b&scoped=true&"
import script from "./CompanyCardDescription.vue?vue&type=script&lang=ts&"
export * from "./CompanyCardDescription.vue?vue&type=script&lang=ts&"
import style0 from "./CompanyCardDescription.vue?vue&type=style&index=0&id=7010d30b&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7010d30b",
  null
  
)

export default component.exports