








import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { sanitizeDescription } from '../../../utils/sanitize-description';

@Component({})
class CompanyCardDescription extends Vue {
  @Prop() description: string;
  closed = true;
  shouldClose = false;

  mounted() {
    this.calculateShouldClose();
    window.addEventListener('resize', this.calculateShouldClose, { passive: true });
  }

  destroyed() {
    window.removeEventListener('resize', this.calculateShouldClose);
  }

  get cardDescription() {
    return sanitizeDescription(this.description);
  }

  @Watch('description')
  calculateShouldClose() {
    this.closed = false;
    this.$nextTick(() => {
      const element = this.$refs.text as HTMLElement;
      if (!element) return;
      this.shouldClose = element.offsetHeight > 84;
      this.closed = true;
    });
  }
}
export default CompanyCardDescription;
