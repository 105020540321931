







import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class EditButton extends Vue {
  @Prop({ type: String, default: 'pencil-alt' }) icon: string;

  onClick() {
    this.$emit('click');
  }
}
export default EditButton;
