










































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { differenceInMonths, format } from 'date-fns';
import { DisplayableWorkExperience, workexperience } from '../../../store/workexperience';
import CompanyCardDescription from './CompanyCardDescription.vue';
import CompanyLogo from '../../shared/CompanyLogo.vue';
import DataCard from '../../shared/DataCard.vue';
import { getUTCDate } from '../../../utils/date';

@Component({
  components: {
    CompanyCardDescription,
    CompanyLogo,
    DataCard,
  },
})
class CompanyCard extends Vue {
  @Prop() workExperience: DisplayableWorkExperience;
  name: string = '';

  created() {
    if (this.$store.state.profile && this.$store.state.profile.fullName) {
      this.name = this.$store.state.profile.fullName;
    } else {
      this.name = this.$store.state.profile.username;
    }
  }

  get isAsOthersSee() {
    return this.$route.name && this.$route.name.indexOf('AsOthersSee') > 0;
  }

  get id() {
    return (this.workExperience.companyName || '').replace(/[^a-z]/gi, '');
  }

  get isCurrent() {
    return this.workExperience.titles[0].currentlyWorkingHere;
  }

  get locationText(): string {
    const location = this.workExperience.titles[0].location;
    if (location === 'Remote, Earth') return 'Remote';
    return location || '';
  }

  openEditModal(id: number) {
    this.$store.commit(workexperience.mutations.OpenEditModal, id - 1);
  }

  formatStartDate(date: string): string {
    return this.formatDate(date);
  }

  formatEndDate(date: string, currentlyWorkingHere: boolean): string {
    return currentlyWorkingHere ? 'Present' : this.formatDate(date);
  }

  formatDate(date: string): string {
    return format(getUTCDate(date), 'MMM yyyy');
  }

  formatInterval(months: number): string {
    let output = '';

    if (months === 0) return 'Less than a month';

    if (months >= 12) {
      output += Math.floor(months / 12);
      output += ' year';
      if (Math.floor(months / 12) !== 1) output += 's';
    }

    if (months % 12 !== 0) {
      if (months >= 12) output += ' ';
      output += months % 12;
      output += ' month';
      if (months % 12 !== 1) output += 's';
    }

    return output;
  }

  calculateMonths(startDate: string, endDate: string, currentlyWorkingHere: boolean): number {
    const end = currentlyWorkingHere ? new Date() : new Date(endDate);
    return differenceInMonths(end, new Date(startDate));
  }

  formatTotalDate(): string {
    const { workExperience } = this;
    if (workExperience.titles.length > 1) {
      return this.formatInterval(workExperience.totalMonths as number);
    }
    const title = workExperience.titles[0];

    return `${this.formatStartDate(title.startDate)} - ${this.formatEndDate(
      title.endDate,
      title.currentlyWorkingHere,
    )} (${this.formatInterval(workExperience.totalMonths as number)})`;
  }

  formatExperienceDate(experience: any) {
    return `${this.formatStartDate(experience.startDate)} - ${this.formatEndDate(
      experience.endDate,
      experience.currentlyWorkingHere,
    )} (${this.formatInterval(
      this.calculateMonths(experience.startDate, experience.endDate, experience.currentlyWorkingHere),
    )})`;
  }

  goCompanyLeaderboard() {
    this.$router.replace({ name: 'companyLeaderboard' });
  }

  removeExperience(id: number) {
    this.$store.dispatch(workexperience.actions.DeleteWorkExperience, id - 1);
  }
}
export default CompanyCard;
