























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import CompanyLogo from './CompanyLogo.vue';
import EditButton from './EditButton.vue';
import { sanitizeDescription } from '../../utils/sanitize-description';

@Component({
  components: {
    CompanyLogo,
    EditButton,
  },
})
class DataCard extends Vue {
  @Prop({ type: String }) title: string;
  @Prop({ type: String, default: '' }) subtitle: string;
  @Prop({ type: String }) image: string;
  @Prop({ type: Boolean, default: false }) imagePlaceholder: boolean;
  @Prop({ type: Array, default: () => [] }) highlightedTags: string[];
  @Prop({ type: Array, default: () => [] }) tags: string[];
  @Prop({ type: String, default: '' }) info: string;
  @Prop({ type: String, default: '' }) date: string;
  @Prop({ type: String, default: '' }) description: string;
  @Prop({ type: String, default: '' }) footer: string;
  @Prop({ type: Boolean, default: false }) editButton: boolean;
  @Prop({ type: Boolean, default: false }) removeButton: boolean;
  @Prop({ type: Boolean, default: false }) nested: boolean;
  @Prop({ type: Boolean, default: false }) inset: boolean;

  get hasImage() {
    return this.image || this.imagePlaceholder || (this.$slots.image && this.$slots.image.length);
  }

  get hasSubtitle() {
    return this.subtitle || (this.$slots.subtitle && this.$slots.subtitle.length);
  }

  get hasTags() {
    return (
      (this.tags && this.tags.length) ||
      (this.highlightedTags && this.highlightedTags.length) ||
      (this.$slots.tags && this.$slots.tags.length)
    );
  }

  get hasDate() {
    return this.date || (this.$slots.date && this.$slots.date.length);
  }

  get hasInfo() {
    return this.info || (this.$slots.info && this.$slots.info.length);
  }

  get hasDescription() {
    return this.description || (this.$slots.description && this.$slots.description.length);
  }

  get hasFooter() {
    return this.footer || (this.$slots.footer && this.$slots.footer.length);
  }

  get cardDescription() {
    return sanitizeDescription(this.description);
  }

  onEditButtonClick() {
    this.$emit('edit');
  }

  onRemoveButtonClick() {
    this.$emit('remove');
  }
}

export default DataCard;
