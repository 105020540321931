





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import stringToColor from '../../utils/string-to-color';

@Component
class CompanyLogo extends Vue {
  @Prop({ type: String }) src: string;
  @Prop({ type: String, default: '' }) name: string;

  get iconColors() {
    const iconColors = stringToColor(this.name || '');
    return {
      bg: iconColors[0],
      text: iconColors[1],
    };
  }
}
export default CompanyLogo;
